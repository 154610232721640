import React from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Hero from '../components/hero'
import SectionContainer from '../components/layout/sectionContainer'
import RelatedArticles from '../components/relatedArticles'
import RichTextRenderer from '../components/richTextRenderer'
import * as pageStyling from '../pages/pageStyling.module.scss'
import Cta from '../components/common/cta'

const WineTermPage = ({ data, pageContext, location }) => {
    const wineTerm = pageContext.wineTerm

    const title = `Vad betyder ${wineTerm.term}?`
    const description = `Lär dig vad ${wineTerm.term.toLowerCase()} och många andra vintermer innebär hos oss på Vinologik - din digitala vinskola. `

    return (
        <>
            <GatsbySeo title={title} description={description} />
            <Hero heading={title} body={description} />
            <div className={pageStyling.defaultWrapper}>
                <SectionContainer title={`${wineTerm.term}`} asColumn={true}>
                    <RichTextRenderer richText={wineTerm.explanation} />
                </SectionContainer>
                <Cta
                    to={'/vintermer'}
                    isLink={true}
                    text={'Upptäck fler vintermer'}
                    isSmall={true}
                />
            </div>
            <RelatedArticles headline={'Senaste artiklarna'} />
        </>
    )
}

export default WineTermPage
